@import "@sx-core/src/scss/bootstrap";
@import "@sx-core/src/scss/backpack";

$foo: 'fo2';

/**
 * Backpack (CSS Reset)
 */

@import "layout/reset_backpack";
/**
 *  Responsive Font Size
 */
@import "~rfs/scss";

/**
 * Import icons
 */
@import "./icon/sass-inline-svg";

/**
 * Custom vars
 */
@import "./vars-figma-colors.ba";
@import "./vars.ba";
@import "./vars-base";

/**
 * Load Editor mixin
 */
@import "./layout/editor";

/**
 * Load mixins
 */
@import "./mixins";

/**
 * Import media dependend scss
 */

@import "@sx-core/src/scss/mixin/breakpoints";
@import "@sx-core/src/scss/mixin/media";

/**
 * Custom vars
 */
@import "./_vars-figma.ba";
@import "./_vars-figma-colors.ba";
@import "./_vars-figma-mixins";

/**
 * Layout styling
 */
//img.lazyload {
//    opacity: 0;
//    transition: opacity 1s 2s;
//}
//
//img.lazyloaded {
//    opacity: 1;
//    transition: opacity 1s 0.3s;
//}

@import "layout/webfont";
@import "layout/default";
@import "layout/helper";

@import "vendor/_hamburger.scss";
@import "element/image";
@import "element/video";
@import "element/intermediate";
@import "element/editor";
@import "element/teaser";
@import "element/filter";
@import "element/accordion";
@import "element/calendar";
@import "element/list";
@import "element/slider";
@import "element/header";
@import "element/footer";
@import "element/nav";

@import "element/keyvisual";
@import "element/breadcrumb";
@import "element/article";
@import "element/directions";
@import "element/downloads";
@import "element/cookiebot";
@import "element/systembar";
@import "element/maintenance";
@import "element/message";
@import "element/error";
@import "element/stoerer";
@import "element/element";
@import "element/button";
@import "element/_gallery.scss";
@import "element/_tides.scss";
@import "element/_room.scss";
@import "element/_fastbooking.scss";
@import "element/_event.scss";
@import "element/_popup.scss";
@import "element/_blog.scss";
@import "element/_jobs.scss";

@import "formular/formular";
@import "formular/input";
@import "formular/textarea";
@import "formular/checkbox";
@import "formular/radio";
@import "formular/chosen";
@import "formular/datetimepicker";

//@import "minimal/minimal";  // im Standard auskommentiert!
//@import "element/iconfont";

@import "ba.scss";

//and now:
@import "element/fear";